<script setup lang="ts">
import {onMounted, reactive, ref} from 'vue';
import {ElTable} from 'element-plus';
import {Prompt} from '@/types/Prompt';
import {autoHeight, formatDate, showLoading, showMessageBox, showNotification} from '@/services/tools';
import {usePromptStore} from "@/stores/promptStore";
import {useI18n} from "vue-i18n";
import NewPrompt from "@/components/NewPrompt.vue";

// 使用国际化功能
const {t} = useI18n();

// 定义响应式变量
const currentPage = ref(1);  // 当前页码
const pageSize = ref(20);  // 每页显示条目数
const totalItems = ref(0);  // 总条目数
const tableData = ref<Prompt[]>([]);  // 表格数据
const publish = ref<string>("-1");  // 发布状态

const tableHeight = autoHeight(190);  // 表格高度自适应
const promptStore = usePromptStore();  // 使用用户存储
const multipleSelection = ref<Prompt[]>([]);  // 多选用户
const promptTable = ref<InstanceType<typeof ElTable> | null>(null);  // 表格实例引用
const showDialog = ref(false);  // 控制新增模板对话框显示
const showEditDialog = ref(false);  // 控制编辑模板对话框显示
const cur_prompt = ref<Prompt>();  // 当前操作的用户
const searchData = reactive({
  keyword: ''  // 搜索关键字
});


/**
 * 处理每页显示条目数变化
 * @param {number} val - 新的每页显示条目数
 */
const handleSizeChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await promptStore.getPrompts(searchData.keyword, Number(publish.value), currentPage.value, val);
    pageSize.value = val;
    tableData.value = promptStore.promptList;
  } finally {
    loading.close();
  }
};

/**
 * 处理当前页码变化
 * @param {number} val - 新的页码
 */
const handleCurrentChange = async (val: number) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await promptStore.getPrompts(searchData.keyword, Number(publish.value), val, pageSize.value);
    currentPage.value = val
    tableData.value = promptStore.promptList
  } finally {
    loading.close();
  }
};

/**
 * 搜索用户
 * @param {string} searchData - 搜索关键字
 */
const searchPrompt = async (searchData: string) => {
  const target = document.querySelector('.el-table') as HTMLElement;
  const loading = showLoading(t, target);

  try {
    totalItems.value = await promptStore.getPrompts(searchData, Number(publish.value), 1, pageSize.value);
    tableData.value = promptStore.promptList;
  } finally {
    loading.close();
  }
};

/**
 * 处理表格行选择变化
 * @param {User[]} val - 选中的用户列表
 */
const handleSelectionChange = (val: Prompt[]) => {
  multipleSelection.value = val;
};

/**
 * 删除用户
 * @param {number} cur_row - 当前操作的行号
 */
const promptsDelete = async (cur_row: number) => {
  const promptIds: number[] = [];
  try {
    // 检查是否有选中的用户
    if (cur_row === -1 && multipleSelection.value.length === 0) {
      await showMessageBox(t, t('message.del-title'), t('message.del-last-one'));
      return;
    }

    // 如果传入的行号有效添加到 promptIds
    if (cur_row !== -1) {
      promptIds.push(cur_row);
    } else {
      promptIds.push(...multipleSelection.value.map(prompt => prompt.prompt_id!));
    }

    // 确认删除操作
    await showMessageBox(t, t('message.del-title'), t('message.del-conform'), false);

    // 执行删除操作
    const target = document.querySelector('.el-table') as HTMLElement;
    const loading = showLoading(t, target);
    await promptStore.del_Prompts(promptIds);

    tableData.value = tableData.value.filter(item =>
        !promptIds.includes(item.prompt_id!)
    );
    totalItems.value = totalItems.value - promptIds.length;
    const count = promptIds.length;
    showNotification("success", t('message.del-success-title'), t('prompt.del-prompt-success', {count}));

    loading.close();
  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    switch (detail) {
      case 'MissingPromptID':
        showNotification("success", t('message.del-success-title'), "任务ID不能为空");
        break;
      case 'null':
        break;
      default:
        showNotification('error', t('message.del-success-title'), error.response.data.detail);
        break;
    }
  }
};

/**
 * 显示对话框
 * @param {boolean} new_prompt - 是否是新增用户
 * @param {User | null} prompt_data - 当前用户数据
 */
const show_dialog = async (new_prompt: boolean, prompt_data: Prompt | null) => {
  if (new_prompt) {
    showDialog.value = true;
    showEditDialog.value = false;
  } else {
    showDialog.value = false;
    showEditDialog.value = true;
    if (prompt_data) {
      cur_prompt.value = prompt_data;
    }
  }
};

// 文本格式化函数
function formatText(text: string): string {
  if (!text) return '';
  // 将换行符替换为 <br> 标签
  return text.replace(/\n/g, '<br>');
}

// 组件挂载后执行的操作
onMounted(async () => {
  const ps_key = localStorage.getItem("prompt_page_size");
  if (ps_key) {
    pageSize.value = Number(ps_key);
  }
  await handleCurrentChange(1);  // 初始化加载第一页数据
});
</script>

<template>
  <el-row>
    <el-col :span="20" class="left">
      <div class="pic-text-display">
        <el-radio-group v-model="publish" @change="searchPrompt(searchData.keyword)">
          <el-radio-button :label="$t('message.all')" value="-1" border/>
          <el-radio-button :label="$t('message.enable')" value="1" border/>
          <el-radio-button :label="$t('message.disable')" value="0" border/>
        </el-radio-group>
        <el-divider direction="vertical" style="height: 25px;"/>
        <el-form ref="searchForm"
                 :model="searchData"
                 :inline="true"
                 class="search-form"
                 @submit.prevent
        >
          <el-form-item prop="searchKey" class="keyword-input">
            <el-input v-model="searchData.keyword" :placeholder="$t('user.SearchKey')"
                      @keyup.enter="searchPrompt(searchData.keyword)"
                      style="width: 200px;"
                      clearable></el-input>
          </el-form-item>
          <el-button type="primary" @click="searchPrompt(searchData.keyword)" circle plain>
            <template #default>
              <font-awesome-icon icon="magnifying-glass"/>
            </template>
          </el-button>
        </el-form>
      </div>
    </el-col>
    <el-col :span="4" class="right">
      <el-button-group>
        <el-button type="primary" @click="show_dialog(true,null)" round>
          <font-awesome-icon icon="plus" class="icon-text-button"/>
          {{ $t('message.create') }}
        </el-button>
        <el-button type="danger" @click="promptsDelete(-1)" round>
          <font-awesome-icon icon="trash-can" class="icon-text-button"/>
          {{ $t('message.delete') }}
        </el-button>
      </el-button-group>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-divider/>
      <el-table
          ref="promptTable"
          :data="tableData"
          stripe
          show-overflow-tooltip
          :height="tableHeight"
          @selection-change="handleSelectionChange">
        <el-table-column type="expand">
          <template #default="props">
            <div class="gray-background">
              <el-row>
                <el-col :span="12" >
                  <div class="div_col">
                    <div v-if="props.row.command">
                      <el-text class="title_text">{{ $t('prompt.command') }}</el-text>
                      <br>
                      <el-text class="multi-line-text">{{ props.row.command }}</el-text>
                    </div>
                    <div v-if="props.row.modify_datetime">
                      <el-text class="title_text">{{ $t('prompt.modify_datetime') }}</el-text>
                      <br>{{ formatDate(props.row.modify_datetime, 'yyyy-MM-dd HH:mm') }}
                    </div>
                  </div>
                </el-col>
                <el-col :span="12" style="align-content: flex-start">
                  <div class="div_col">
                     <div v-if="props.row.output_parser">
                      <el-text class="title_text">{{ $t('prompt.output_parser') }}</el-text>
                      <br>
                      <el-text class="multi-line-text">{{ props.row.output_parser }}</el-text>
                    </div>
                  </div>
                </el-col>
                <div v-if="props.row.prompt_items && props.row.prompt_items.length > 0" style="width: 100%">
                  <el-divider content-position="left">
                    <div class="title_text">{{ $t('prompt.prompt_items') }}</div>
                  </el-divider>
                  <el-table
                      :data="props.row.prompt_items"
                      :default-sort="{ prop: 'item_sort', order: 'ascending' }"
                      class="sub-table">
                    <el-table-column :label="$t('prompt.item_sort')" prop="item_sort" width="60px" align="center"/>
                    <el-table-column :label="$t('prompt.item_name')" prop="item_name" width="130px"
                                     class="multi-line-text">
                      <template #default="scope">
                        <span v-html="formatText(scope.row.item_name)"></span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('prompt.item_intro')" prop="item_intro" width="300px"
                                     class="multi-line-text">
                      <template #default="scope">
                        <span v-html="formatText(scope.row.item_intro)"></span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('prompt.item_default')" prop="item_default" class="multi-line-text">
                      <template #default="scope">
                        <span v-html="formatText(scope.row.item_default)"></span>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-row>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="30"/>
        <el-table-column fixed="left" width="30">
          <template #default="{ row }">
            <span>
              <span
                  v-if="row.publish"
                  class="status-dot green"
              ></span>
              <span
                  v-else
                  class="status-dot red"
              ></span>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="name" :label="$t('prompt.name')" width="260" sortable></el-table-column>
        <el-table-column prop="rb_id" :label="$t('prompt.rb_id')" width="60" align="center"></el-table-column>
        <el-table-column prop="description"
                         :label="$t('prompt.description')"
                         class="multi-line-text">
          <template #default="scope">
            <span v-html="formatText(scope.row.description)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="create_datetime" :label="$t('user.create-date')" width="150" align="center" sortable>
          <template #default="{ row }">{{ formatDate(row.create_datetime, 'yyyy-MM-dd HH:mm') }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('message.action')" width="120" align="center">
          <template #default="{ row }">
            <el-button type="primary" size="small" circle plain @click="show_dialog(false,row)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" @click="promptsDelete(row.prompt_id)" circle plain>
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <NewPrompt :modelValue="showDialog" @update:isShow="showDialog = $event"
                 @refreshList="handleCurrentChange(1);"/>
      <NewPrompt :modelValue="showEditDialog" :prompt_info="cur_prompt" @update:isShow="showEditDialog = $event"
                 @refreshList="handleCurrentChange(1);"/>
      <el-pagination
          v-model:current-page="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[20, 50, 100, 200]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalItems"
          @size-change="handleSizeChange(pageSize)"
          @current-change="handleCurrentChange(currentPage)"
      />

    </el-col>
  </el-row>

</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.sub-table {
  border-radius: 20px;
}

.search-form {
  margin: 0;
  padding: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.keyword-input {
  margin: 0 10px 0 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;;
  align-items: self-start;

}

.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: self-start;

}

.div_col {
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  gap: 10px; /* 设置间距 */
}

.gray-background {
  background-color: $menu-bg; /* 设置为你想要的灰色 */
  padding: 20px; /* 可选：添加一些内边距让内容不贴着边 */
  margin-left: 80px;
  margin-right: 15px;
  border-radius: 20px; /* 添加圆角 */
  html.dark & {
    background-color: $main-background-dark;
  }
}

.el-divider {
  background-color: #EBEDF0;

  html.dark & {
    background-color: $main-background-dark;
  }
}

.title_text {
  font-weight: bold;
  color: $table-header-text-color;

}

.el-button {
  width: auto;
}

.el-row {
  height: auto;
}

:deep(.el-divider__text) {
  background-color: $menu-bg !important;
  border-radius: 10px !important;
}

</style>