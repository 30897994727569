import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dialog-header" }
const _hoisted_2 = { class: "col-left" }
const _hoisted_3 = {
  key: 0,
  class: "div-row"
}
const _hoisted_4 = { class: "col-right" }

import {computed, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance, genFileId, UploadFile, UploadInstance, UploadProps, UploadRawFile} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {User} from "@/types/User" // 导入 User 类型定义
import {useUserStore} from '@/stores/userStore' // 导入用户存储
import {showNotification} from "@/services/tools" // 导入工具函数
import {validateConfirmPassword, validateMobile, validatePassword} from "@/services/validators"
// 使用用户存储和国际化

export default /*@__PURE__*/_defineComponent({
  __name: 'NewUser',
  props: { // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  userinfo: {
    type: Object as () => User,
    default: null
  }
},
  emits: ["refreshList", "update:isShow"],
  setup(__props, { emit: __emit }) {

const userStore = useUserStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newUserMessage = ref<string>('') // 新用户信息提示
const userForm = ref<FormInstance>() // 用户表单实例
const createUserDialog = ref(false) // 创建用户对话框显示状态
const avatarPic = ref<File | null>(null) // 头像文件
const avatar_upload = ref<UploadInstance>() // 上传实例
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const user = ref<User>({} as User) // 创建用户对象
const UserData = ref({ // 用户数据对象
  avatar_url: '',
  nickName: '',
  loginName: '',
  password: '',
  confirmPassword: '',
  mobile: '',
  email: '',
  membership_end_date: null as Date | null,
  native_language: '',
  fee_times: 0,
  role: false
})
// 初始化用户数据的默认值
const defaultUserData = {
  avatar_url: '',
  nickName: '',
  loginName: '',
  password: '',
  confirmPassword: '',
  mobile: '',
  email: '',
  membership_end_date: null as Date | null,
  native_language: '',
  fee_times: 0,
  role: false
};

const disabledDate = (time: Date) => {
  return time.getTime() < new Date().setHours(0, 0, 0, 0)
}

// 定义组件的 props 和 emits
const props = __props
const emit = __emit // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  createUserDialog.value = val // 设置对话框显示状态
  newUserMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.userinfo 的变化并初始化相关状态
watch(() => props.userinfo, (newVal) => {
  if (newVal) {
    user.value = newVal;
    ini_form(user.value)
  } else {
    user.value = {} as User;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const userRules = computed(() => ({
  nickName: [{required: false, max: 30, message: t('user.nickname-check'), trigger: 'blur'}], // 昵称验证规则
  loginName: [{required: true, max: 30, message: t('user.reg-login-name-check'), trigger: 'blur'}], // 登录名验证规则
  password: [
    {
      required: !user.value.user_id,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validatePassword(t, rule, value, callback, !user.value.user_id) // 密码验证规则
    }
  ],
  confirmPassword: [
    {
      required: !user.value.user_id,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validateConfirmPassword(t, rule, value, callback, UserData.value.password) // 确认密码验证规则
    }
  ],
  mobile: [{
    required: false,
    validator: (rule: any, value: any, callback: any) => validateMobile(t, rule, value, callback), // 手机号码验证规则
    trigger: 'blur'
  }],
  email: [{type: 'email', message: t('user.email-invalid'), trigger: ['blur', 'change']}], // 格式验证
}))

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    createUserDialog.value = false // 隐藏对话框

    newUserMessage.value = "" // 清空提示信息
    avatar_upload.value!.clearFiles() //清空文件列表
    avatarPic.value = null //清空文件
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}

// 上传文件超出限制时的处理
const handleExceed: UploadProps['onExceed'] = (files) => {
  try {
    avatar_upload.value!.clearFiles() // 清空文件列表
    const file = files[0] as UploadRawFile
    file.uid = genFileId() // 生成文件 ID
    avatar_upload.value!.handleStart(file) // 开始上传文件
    avatarPic.value = file // 更新头像文件
  } catch (error) {
    console.error('Error handling file exceed:', error) // 打印错误信息
  }
}

// 上传文件状态改变时的处理
const handleChange: UploadProps['onChange'] = (uploadFile: UploadFile) => {
  try {
    UserData.value.avatar_url = ""
    if (uploadFile.status === 'ready' && uploadFile.raw) {
      avatarPic.value = uploadFile.raw as File // 更新头像文件
    }
  } catch (error) {
    console.error('Error handling file change:', error) // 打印错误信息
  }
}

// 上传头像之前的检查
const beforeAvatarUpload = (rawFile: File | null) => {
  try {
    if (!rawFile) return true
    const isAllowedType = ['image/jpeg', 'image/png', 'image/gif'].includes(rawFile.type) // 检查文件类型
    if (!isAllowedType) {
      newUserMessage.value = t('user.avatar_format')
      return false
    }
    if (rawFile.size / 1024 / 1024 > 2) {
      newUserMessage.value = t('user.avatar_size')
      return false
    }

    newUserMessage.value = ""
    avatarPic.value = rawFile
    return true
  } catch (error) {
    console.error('Error before uploading avatar:', error) // 打印错误信息
    return false
  }
}

// 上传用户信息
const save_user = async (formEl: FormInstance | undefined) => {
  if (!formEl) return


  try {
    user.value.avatar_url = UserData.value.avatar_url // 设置用户头像 URL
    user.value.name = UserData.value.nickName // 设置用户昵称
    user.value.login_name = UserData.value.loginName // 设置用户登录名
    user.value.password = UserData.value.password // 设置用户密码
    user.value.mobile = UserData.value.mobile // 设置用户手机
    user.value.email = UserData.value.email // 设置用户邮箱
    user.value.native_language = UserData.value.native_language // 设置用户母语
    user.value.role = UserData.value.role ? 1 : 0 // 设置管理员角色

    if (UserData.value.membership_end_date) {
      //会员结束日设置为选择日期的23:59:59
      const temp_date = new Date(UserData.value.membership_end_date)
      // 设置小时为当天的 23:59:59
      temp_date.setHours(23, 59, 59, 0)
      // 使用 moment 格式化为本地时间字符串（YYYY-MM-DD HH:MM:SS）
      // 保存修改后的日期字符串到 user.value.membership_end_date
      user.value.membership_end_date = temp_date;

    } else {
      user.value.membership_end_date = null
    }

    if (UserData.value.fee_times === 0) {
      user.value.fee_times = 1
    } else {
      user.value.fee_times = UserData.value.fee_times
    }


    newUserMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        if (!beforeAvatarUpload(avatarPic.value)) return
        const response = await userStore.register(user.value, avatarPic.value) // 注册用户，允许 avatarPic 为 null
        if (response === '0') {
          UserData.value = {...defaultUserData} //清空userData数据
          refreshFatherList() // 刷新父组件列表
          await closeDialog() // 关闭对话框
          showNotification('success', t('message.success'), t('user.newuser-success-info')) // 显示成功通知
        }
      }
    } catch (error: any) {
      let detail = "null"
      newUserMessage.value = "" // 显示验证错误提示
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'LoginNameExists':
          newUserMessage.value = t('user.newuser-name-exist'); // 注册名已存在
          break;
        case 'UserNotFound':
          newUserMessage.value = t('user.newuser-name-exist'); // 注册名已存在
          break;
        case 'CreateSchemaFailed':
          newUserMessage.value = t('user.create-schema-error'); // 未知错误
          break;
        case 'null':
          break;
        default:
          showNotification('error', '未知状态', error.response.data.detail);
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newUserMessage.value = t('user.unknown-error') // 显示未知错误提示
  }
}

const ini_form = async (user: User) => {
  if (user) {
    UserData.value.avatar_url = user.avatar_url || '' // 设置用户头像 URL
    UserData.value.nickName = user.name || ''// 设置用户昵称
    UserData.value.loginName = user.login_name || '' // 设置用户登录名
    UserData.value.password = '' // 设置用户密码
    UserData.value.mobile = user.mobile || ''// 设置用户手机
    UserData.value.email = user.email || ''// 设置用户邮箱
    UserData.value.membership_end_date = user.membership_end_date || null; //设置会员到期日
    UserData.value.fee_times = user.fee_times || 0
    UserData.value.native_language = user.native_language || ''// 设置用户母语
    UserData.value.role = user.role == 1 //设置管理员角色
  }
}


return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    visible: createUserDialog.value,
    width: "900px",
    "align-center": "",
    center: "",
    draggable: "",
    "close-on-click-modal": false,
    "show-close": false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('user.register-title')), 1),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          circle: "",
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: "xmark" })
          ]),
          _: 1
        })
      ])
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (save_user(userForm.value)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.save')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "danger",
          onClick: closeDialog,
          plain: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, {
              icon: "xmark",
              class: "icon-text-button"
            }),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('message.cancel')), 1)
          ]),
          _: 1
        })
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref_key: "userForm",
        ref: userForm,
        model: UserData.value,
        rules: userRules.value,
        "label-width": "100px",
        "label-position": 'right'
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              (newUserMessage.value)
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    type: "warning",
                    description: newUserMessage.value,
                    closable: false,
                    "show-icon": "",
                    effect: 'light',
                    class: "reg-alert"
                  }, null, 8, ["description"]))
                : _createCommentVNode("", true),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.nickname'),
                      prop: "nickName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: UserData.value.nickName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((UserData.value.nickName) = $event)),
                          placeholder: _ctx.$t('user.nickname-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "30"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.login-name'),
                      prop: "loginName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: UserData.value.loginName,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((UserData.value.loginName) = $event)),
                          placeholder: _ctx.$t('user.login-name-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "20"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.password'),
                      prop: "password"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "password",
                          modelValue: UserData.value.password,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((UserData.value.password) = $event)),
                          placeholder: _ctx.$t('user.input-password-tip'),
                          "show-password": "",
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "50"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.confirm-password'),
                      prop: "confirmPassword"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          type: "password",
                          modelValue: UserData.value.confirmPassword,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((UserData.value.confirmPassword) = $event)),
                          placeholder: _ctx.$t('user.confirm-password-tip'),
                          autocomplete: "off",
                          "show-password": "",
                          clearable: ""
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      class: "avatar-div",
                      label: _ctx.$t('user.avatar')
                    }, {
                      default: _withCtx(() => [
                        (UserData.value.avatar_url)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                              _createVNode(_component_el_image, {
                                src: UserData.value.avatar_url,
                                alt: "Preview Image",
                                fit: "cover",
                                class: "avatar-preview"
                              }, null, 8, ["src"])
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_el_upload, {
                          "list-type": "picture-card",
                          "on-exceed": handleExceed,
                          "on-change": handleChange,
                          "on-preview": handlePictureCardPreview,
                          limit: 1,
                          "auto-upload": false,
                          ref_key: "avatar_upload",
                          ref: avatar_upload
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_font_awesome_icon, {
                              icon: "circle-plus",
                              class: "avatar-uploader-icon"
                            })
                          ]),
                          _: 1
                        }, 512),
                        _createVNode(_component_el_dialog, {
                          modelValue: dialogVisible.value,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((dialogVisible).value = $event)),
                          width: "400px"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_image, {
                              src: dialogImageUrl.value,
                              alt: "Preview Image",
                              fit: "cover",
                              class: "preview_pic"
                            }, null, 8, ["src"])
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 12 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.mobile'),
                      prop: "mobile"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: UserData.value.mobile,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((UserData.value.mobile) = $event)),
                          placeholder: _ctx.$t('user.mobile-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "15"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.email'),
                      prop: "email"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: UserData.value.email,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((UserData.value.email) = $event)),
                          placeholder: _ctx.$t('user.email-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "40"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.native_language'),
                      prop: "native_language"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: UserData.value.native_language,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((UserData.value.native_language) = $event)),
                          placeholder: _ctx.$t('user.native_language-tip'),
                          clearable: "",
                          "show-word-limit": "",
                          maxlength: "20"
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.membership_end_date'),
                      prop: "membership_end_date"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_date_picker, {
                          modelValue: UserData.value.membership_end_date,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((UserData.value.membership_end_date) = $event)),
                          type: "date",
                          placeholder: _ctx.$t('user.membership_end_date_tip'),
                          "disabled-date": disabledDate,
                          size: 'default',
                          style: {"width":"100%"}
                        }, null, 8, ["modelValue", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('user.manager-role'),
                      prop: "role"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: UserData.value.role,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((UserData.value.role) = $event)),
                          "inline-prompt": "",
                          "active-text": _ctx.$t('message.yes'),
                          "inactive-text": _ctx.$t('message.no')
                        }, null, 8, ["modelValue", "active-text", "inactive-text"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})