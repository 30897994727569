<template>
  <div class="chat-window">
    <el-scrollbar ref="scrollbarRef" class="chat-content" :height="contentHeight">
      <div class="message-wrapper">
        <div v-for="(message, index) in messages" :key="message.createdAt" :class="['message-row', message.role]">
          <div class="avatar-container" v-if="message.role !== 'user'">
            <el-avatar :src="ai_avatarUrl" class="avatar-display-left"></el-avatar>
          </div>
          <div class="message-col">
            <div :class="['message', message.role]" :ref="'message-' + index">
              <div v-if="!editState[index]" class="markdown-body"
                   v-html="renderContent(message, index)"></div>

              <div v-if="message.role !== 'user' && llmStore.streamAnswer[index]=='' ">
                <el-image :src="loadingImage"></el-image>
              </div>
              <div v-else v-show="editState[index]">
                <el-input
                    v-model="tempEditTxt"
                    resize="none"
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 20 }"
                    class="edit-body"
                    :style="{ width: inputWidths[index] }"
                    :ref="(el:HTMLTextAreaElement) => setEditInputRef(el, index)"
                ></el-input>
                <el-button
                    round
                    type="primary"
                    @click="submitEdit(index)"
                    class="edit-button"
                >{{ $t('message.send') }}
                </el-button>
                <el-button
                    round plain
                    type="info"
                    @click="cancelEdit(index)"
                    class="edit-button"
                >{{ $t('message.cancel') }}
                </el-button>
              </div>
            </div>

            <div v-if="message.role !== 'user'" class="left-button">
              <el-tooltip
                  :content="$t('message.copy')"
                  placement="bottom"
              >
                <el-button
                    circle
                    size="small"
                    class="small-button"
                    @click="copyMessage(index)"
                >
                  <font-awesome-icon :icon="iconState[index] ? 'check' : 'copy'"/>
                </el-button>
              </el-tooltip>
              <el-tooltip :content="$t('chat.resubmit')"
                          placement="bottom"
              >
                <el-button
                    circle
                    size="small"
                    class="small-button"
                    @click="reSendMsg(index)"
                >
                  <font-awesome-icon icon="arrows-rotate"/>
                </el-button>
              </el-tooltip>
            </div>
            <div v-else class="right-button">
              <el-tooltip
                  :content="$t('message.modify')"
                  placement="bottom"
              >
                <el-button circle size="small" class="small-button" @click="editMessage(index)">
                  <font-awesome-icon icon="pen"/>
                </el-button>
              </el-tooltip>
            </div>
          </div>
          <div class="avatar-container" v-if="message.role === 'user'">
            <el-avatar :src="avatarUrl" class="avatar-display-right"></el-avatar>
          </div>
        </div>
      </div>
    </el-scrollbar>

    <div class="down-area">
      <el-button
          type="info"

          circle
          size="large"
          class="attached-button">
        <template #default>
          <font-awesome-icon icon="paperclip" class="icon-enabled"/>
        </template>
      </el-button>
      <el-input
          v-model="newMessage"
          type="textarea"
          :placeholder="$t('robot.input-tip')"
          @keyup="handleKeyUp"
          class="input-area"
          :autosize="{ minRows: 1, maxRows: 20 }"
          resize="none"
      >
      </el-input>
      <el-button
          type="info"
          @click="sendMessage"
          circle
          size="large"
          :disabled="!newMessage.trim()"
          class="send-button">
        <template #default>
          <font-awesome-icon icon="circle-arrow-up"
                             :class="{ 'icon-enabled': newMessage.trim(), 'icon-disabled': !newMessage.trim() }"
          />
        </template>
      </el-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import {nextTick, onMounted, ref, watch} from 'vue';
import {autoHeight, showNotification} from "@/services/tools";
import MarkdownIt from 'markdown-it';
import markdownItMathjax from 'markdown-it-mathjax3';
import 'github-markdown-css/github-markdown.css';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';
import Clipboard from 'clipboard';
import {ElScrollbar} from 'element-plus';
import {useI18n} from "vue-i18n";
import {useUserStore} from '@/stores/userStore';
import {ChatMessage} from '@/types/LLM';
import {useLLMStore} from '@/stores/llmStore';
import loadingImage from '@/assets/loading.gif';
import {SystemOption} from "@/types/system";

const emit = defineEmits(['messagesResponse']);

// 定义用户头像的URL
const avatarUrl = ref('');
const ai_avatarUrl = ref('');

// 国际化工具
const {t} = useI18n();
// 滚动条引用
const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>();
// 用户存储
const userStore = useUserStore();
const llmStore = useLLMStore();
// 定义消息和UI状态的响应式属性
const messages = ref<ChatMessage[]>([]);
const newMessage = ref("");
const iconState = ref<boolean[]>([]);
const editState = ref<boolean[]>([]);
const editInputRefs = ref<(HTMLElement | null)[]>([]);
const inputWidths = ref<string[]>([]);
const tempEditTxt = ref('');
const sessionId = ref('');
const sessionName = ref('');

// 配置MarkdownIt用于高亮代码块
const md: MarkdownIt = new MarkdownIt({
  highlight: function (str, lang) {
    // 预处理：转换所有需要的字符到HTML实体
    const safeStr = str
        .replace(/&/g, '&#38;')
        .replace(/</g, '&#60;')
        .replace(/>/g, '&#62;')
        .replace(/"/g, '&#34;')
        .replace(/'/g, '&#39;')
        .replace(/\n/g, '&#10;');

    // 生成代码块的公共头部HTML
    const headerHtml = `<div class="code-header">
<span class="code-lang">${lang || 'text'}</span>
<button class="copy-button" data-clipboard-text="${safeStr}">` + t('robot.copy-code') + `</button>
</div>`;

    let codeHtml;

    // 如果指定了语言且支持，则高亮代码
    if (lang && hljs.getLanguage(lang)) {
      try {
        codeHtml = hljs.highlight(str, {language: lang}).value;
      } catch (error) {
        console.error('代码高亮失败:', error);
        codeHtml = md.utils.escapeHtml(str);
      }
    } else {
      codeHtml = md.utils.escapeHtml(str);
    }

    // 返回完整的代码块HTML
    return `${headerHtml}<pre class="hljs"><code>${codeHtml}</code></pre>`;
  }
});

// 包装数学公式
const wrapMathEquations = (content: string): string => {
  if (!content) return content
  // 处理行内公式
  content = content.replace(/\\\((.*?)\\\)/g, (match) => `$${match.slice(2, -2).trim()}$`);
  // 处理块级公式
  content = content.replace(/\\\[[^\]]+\\]/g, (match) => `$$${match.slice(2, -2).trim()}$$`);

  return content;
};

// 设置内容高度
const contentHeight = autoHeight(160);

// 渲染Markdown内容的函数
const renderMarkdown = (content: string) => {
  if (!content) return content;
  const formattedContent = content.replace(/\n/g, '  \n');
  return md.render(formattedContent);
};

// 转义 HTML，避免 XSS 注入
const escapeHtml = (text: string): string => {
  return text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
    .replace(/\n/g, '<br>');
};

// 处理内容的函数
const renderContent = (message: any, index: number) => {
  if (message.role === 'user') {
    return escapeHtml(message.content);
  } else {
    return renderMarkdown(wrapMathEquations(llmStore.streamAnswer[index]));
  }
};
// MarkdownIt插件：处理视频
function videoPlugin(md: MarkdownIt) {
  // 默认的图像处理规则
  const defaultRender = md.renderer.rules.image || function (tokens, idx, options, self) {
    return self.renderToken(tokens, idx, options);
  };

  // 自定义图像处理规则
  md.renderer.rules.image = function (tokens, idx, options, env, self) {
    const token = tokens[idx];
    const src = token.attrs ? token.attrs[token.attrIndex('src')][1] : '';

    // 如果是视频链接，则生成 <video> 标签
    if (src.endsWith('.mp4')) {
      return `
        <video controls width="100%" height="auto">
          <source src="${src}" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      `;
    }

    // 否则使用默认的渲染规则
    return defaultRender(tokens, idx, options, env, self);
  };
}

// 再次发送消息的函数
const reSendMsg = async (index: number) => {
  if (!index) return;
  try {
    messages.value.splice(index)
    llmStore.streamAnswer.splice(index - 1);
    messages.value.push({
      createdAt: Date.now(),
      session_id: "",
      session_name: "",
      content: "",
      sys_content: "",
      role: 'ai',
      topic: '',
      robot_content: '',
      relevance: false,
      no_prompt: 1,
      lang: "中文",
      source:"",
    });
    await processLLMResponse(messages.value);
  } catch (error) {
    console.error('发送消息失败:', error);
  }
}

// 发送消息的函数
const sendMessage = async () => {
  // 清空编辑状态
  editState.value = editState.value.map(() => false);


  try {
    if (!userStore.loginUser) {
      return
    }
    messages.value.push({
      createdAt: Date.now(),
      session_id: sessionId.value == '' ? Date.now().toString() + 'U' + userStore.loginUser.user_id?.toString() : sessionId.value,
      session_name: sessionName.value == '' ? '新话题' + Date.now().toString().slice(-6) : sessionName.value,
      content: newMessage.value,
      sys_content: newMessage.value,
      role: 'user',
      topic: messages.value.length > 0 ? messages.value[messages.value.length - 2].topic : '',
      robot_content: messages.value.length > 0 ? messages.value[messages.value.length - 2].robot_content : '',
      lang: "中文",
      relevance: false,
      no_prompt: 1,
      source:"",
    });

    newMessage.value = '';

    messages.value.push({
      createdAt: Date.now(),
      session_id: "",
      session_name: "",
      content: "",
      sys_content: "",
      role: 'ai',
      topic: '',
      robot_content: '',
      lang: "中文",
      relevance: false,
      no_prompt: 1,
      source:"",
    });

    // 调用提取出来的函数来处理LLM响应
    await processLLMResponse(messages.value);


  } catch (error) {
    console.error('发送消息失败:', error);
  }
};

const processLLMResponse = async (msgs: ChatMessage[]) => {
  const lastMsgIndex = msgs.length - 1;

  try {
    const data = await llmStore.sendToLLMStreaming(msgs.slice(0, -1));

    msgs[lastMsgIndex].content = llmStore.streamAnswer[lastMsgIndex];
    msgs[lastMsgIndex - 1].sys_content = llmStore.chat_msg.sys_content;
    msgs[lastMsgIndex - 1].relevance = llmStore.chat_msg.relevance;
    msgs[lastMsgIndex - 1].robot_content = llmStore.chat_msg.robot_content;
    msgs[lastMsgIndex - 1].topic = llmStore.chat_msg.topic;

    // 保存聊天记录
    await llmStore.saveNowChat(msgs.slice(-2));

    // 通过 emit 回传 megs 对象给父组件
    emit('messagesResponse', msgs);

  } catch (error: any) {
    let detail = "null"
    if (error.response) {
      detail = error.response.data.detail;
    }
    msgs.pop();
    switch (detail) {
      case 'InvalidInput':
        showNotification('error', '发送消息', "参数不正确");
        break;
      case 'BdAPIToken':
        showNotification('error', '发送消息', "百度合规检查接口错误");
        break;
      case 'SteamError':
        llmStore.streamAnswer[lastMsgIndex] = t("message.llm-error-info");
        msgs[lastMsgIndex].content = llmStore.streamAnswer[lastMsgIndex];
        msgs[lastMsgIndex - 1].sys_content = llmStore.chat_msg.sys_content;
        msgs[lastMsgIndex - 1].relevance = llmStore.chat_msg.relevance;
        msgs[lastMsgIndex - 1].robot_content = llmStore.chat_msg.robot_content;
        msgs[lastMsgIndex - 1].topic = llmStore.chat_msg.topic;
        break;
      case 'null':
        break;
      default:
        showNotification('error', '未知状态', error.response.data.detail);
        break;
    }
    return null;
  }

};


// 滚动到聊天窗口底部的函数
const scrollToBottom = () => {
  nextTick(() => {
    if (scrollbarRef.value && scrollbarRef.value.wrapRef) {
      scrollbarRef.value.setScrollTop(scrollbarRef.value.wrapRef.scrollHeight);
    }
  });
};


// 暴露方法给父组件调用
const setMessages = (chatHistory: ChatMessage[]) => {
  // 清空现有的数组内容，但保持引用
  messages.value.splice(0, messages.value.length, ...chatHistory);
  scrollToBottom()
};
const setSession = (id: string, name: string) => {
  sessionId.value = id;
  sessionName.value = name;
}
// 暴露 messages 变量直接供父组件操作
defineExpose({setMessages, setSession});

// 处理按键事件，Ctrl + Enter 发送消息
const handleKeyUp = (event: KeyboardEvent) => {
  if (event.ctrlKey && event.key === 'Enter' && newMessage.value != "") {
    sendMessage();
  }
};

// 复制消息内容的函数
const copyMessage = (index: number) => {
  try {
    const messageElement = document.querySelector(`.message-row:nth-child(${index + 1}) .message`) as HTMLElement;
    const messageText = messageElement?.innerText || messageElement?.textContent || '';
    navigator.clipboard.writeText(messageText).then(() => {
      iconState.value[index] = true;
      setTimeout(() => {
        iconState.value[index] = false;
      }, 1000);
    }).catch(err => {
      console.error('复制失败:', err);
    });
  } catch (error) {
    console.error('复制消息失败:', error);
  }
};

// 设置编辑输入框引用
const setEditInputRef = (el: HTMLTextAreaElement | null, index: number) => {
  editInputRefs.value[index] = el;
};

// 编辑消息的函数
const editMessage = (index: number) => {
  try {
    const messageElement = document.querySelector(`.message-col`) as HTMLElement;
    inputWidths.value[index] = messageElement.offsetWidth * 0.75 + 'px';
    tempEditTxt.value = messages.value[index].content;
    editState.value = editState.value.map(() => false);
    editState.value[index] = true;
    nextTick(() => {
      const inputElement = editInputRefs.value[index];
      if (inputElement) {
        inputElement.focus();
      }
    });
  } catch (error) {
    console.error('编辑消息失败:', error);
  }
};

// 取消编辑消息
const cancelEdit = (index: number) => {
  editState.value[index] = false;
};

// 提交编辑后的消息
const submitEdit = (index: number) => {
  try {
    messages.value[index].content = tempEditTxt.value;
    messages.value[index].sys_content = tempEditTxt.value;
    editState.value[index] = false;
    reSendMsg(index + 1);
  } catch (error) {
    console.error('提交编辑消息失败:', error);
  }
};

// 监听消息变化，自动滚动到底部
watch([messages, llmStore.streamAnswer], () => {
  scrollToBottom();
});


// 组件挂载时初始化
onMounted(() => {
  try {

    new Clipboard('.copy-button');
    messages.value.length = 0
    llmStore.streamAnswer.length = 0
    if (userStore.loginUser) {
      avatarUrl.value = userStore.loginUser.avatar_url || ''
    }
    ai_avatarUrl.value = SystemOption['ai_avatar']
    md.use(videoPlugin)
    md.use(markdownItMathjax)
  } catch (error) {
    console.error('初始化失败:', error);
  }
});
</script>


<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';

.chat-window {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  margin: 0;
  padding: 0;
  height: 100%; /* 确保窗口占据整个视口的高度 */
}

.chat-content {
  padding-right: 15px;
  padding-left: 15px;

  html.dark & {
    background-color: $main-background-dark; /* 深色模式背景色 */
  }
}


.down-area {
  margin-top: 15px;
  display: flex; /* 使用Flex布局 */
  flex-direction: row; /* 横向排列 */
  align-items: flex-end; /* 底部对齐 */
  justify-content: center; /* 水平居中 */
  position: relative;
  border: none;
  box-shadow: none;
  padding: 10px 8px 10px 8px;
  border-radius: 30px;
  background-color: $input-background-light; /* 浅色模式输入框背景色 */

  html.dark & {
    background-color: $input-background-dark; /* 深色模式输入框背景色 */
  }
}

.small-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.send-button {
  background-color: transparent;
  border: none;
  font-size: 30px;
}

.attached-button {
  background-color: transparent;
  border: none;
  font-size: 26px;
}

.send-button:disabled {
  background-color: transparent !important;
}

.message-wrapper {
  margin: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.message-row {
  display: flex;
  align-items: flex-start;
}

.message-col {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avatar-container {
  display: flex;
  align-items: flex-start;
}

.message {
  margin-bottom: 5px;
  padding: 10px 20px; /* 增加填充，使其更像气泡 */
  border-radius: 18px;
  max-width: 75%;
  word-wrap: break-word; /* 处理长单词 */
  word-break: break-all; /* 处理所有字符 */
  position: relative; /* 用于箭头定位 */
}

.message.user {
  background-color: $chat-pop; /* 浅色模式发送消息背景色 */
  margin-left: auto;

  html.dark & {
    background-color: $chat-pop-dark; /* 深色模式发送消息背景色 */
  }
}

.message.user::after {
  content: '';
  position: absolute;
  right: -6px; /* 向右侧定位 */
  top: 10px; /* 垂直定位 */
  width: 0;
  height: 0;
  border-left: 15px solid $chat-pop; /* 浅色模式发送消息箭头颜色 */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  html.dark & {
    border-left: 15px solid $chat-pop-dark; /* 深色模式发送消息箭头颜色 */
  }
}

.message.ai {
  margin-right: auto;
  //background-color: $chat-ai-pop; /* 浅色模式接收消息背景色 */
  background-color: white;

  html.dark & {
    background-color: $chat-ai-dark; /* 深色模式接收消息背景色 */
  }
}

.message.ai::after {
  content: '';
  position: absolute;
  left: -6px; /* 向左侧定位 */
  top: 10px; /* 垂直定位 */
  width: 0;
  height: 0;
  border-right: 15px solid white; /* 浅色模式接收消息箭头颜色 */
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  html.dark & {
    border-right: 15px solid $chat-ai-dark; /* 深色模式接收消息箭头颜色 */
  }
}

.avatar-display-right {
  margin-left: 15px;
}

.avatar-display-left {
  margin-right: 15px;
}

.left-button {
  display: flex;
  justify-content: flex-start;
  margin-left: 5px;
  height: 30px; /* 预留按钮的高度 */
  opacity: 0; /* 默认透明 */
  transition: opacity 0.3s ease; /* 渐隐渐显动画 */
}

.right-button {
  display: flex;
  height: 30px;
  justify-content: flex-end;
  margin-right: 5px;
  opacity: 0; /* 默认透明 */
  transition: opacity 0.5s ease; /* 渐隐渐显动画 */
}

.message-col:hover .right-button,
.message-col:hover .left-button {
  opacity: 1; /* 鼠标悬停时显示 */
}

.edit-body :deep(.el-textarea__inner) {
  font-size: 16px;
  border: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  line-height: 1.8;
  background-color: $chat-pop; /* 浅色模式编辑框背景色 */

  html.dark & {
    background-color: $chat-pop-dark; /* 深色模式编辑框背景色 */
    color: $footer-font-color-light;
  }
}

.edit-button {
  margin-top: 10px;
  margin-left: 15px;
  width: auto;
  float: right;
  border: none;
}

.input-area :deep(.el-textarea__inner) {
  font-size: 16px;
  border: none;
  box-shadow: none;
  background-color: $input-background-light; /* 浅色模式输入框背景色 */
  padding: 5px;
  margin-bottom: 3px;

  html.dark & {
    background-color: $input-background-dark; /* 深色模式输入框背景色 */
  }
}

.input-area :deep(.el-textarea__inner:focus) {
  border: none;
  box-shadow: none;
}

.icon-enabled {
  color: $enabled; /* 浅色模式启用图标颜色 */

  html.dark & {
    color: $enabled-dark; /* 深色模式启用图标颜色 */
  }
}

.icon-disabled {
  color: $disabled; /* 浅色模式禁用图标颜色 */

  html.dark & {
    color: $disabled-dark; /* 深色模式禁用图标颜色 */
  }
}

:deep(.el-scrollbar__bar) {
  width: 10px; /* 设置滚动条的宽度 */
}

:deep(.el-scrollbar__thumb) {
  background-color: $header-font-color-light; /* 设置滚动条滑块的颜色 */
}

</style>
