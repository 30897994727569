<script setup lang="ts">
import {onMounted, ref, watch} from 'vue';
import {
  autoHeight,
  del_tree_node,
  get_tree,
  get_user_label,
  save_tree_node,
  showMessageBox,
  showNotification
} from "@/services/tools";
import treeIcon from '@/components/treeIcon.vue'; // 引入你的自定义图标组件
import {LabelUserRelation, TreeNode} from "@/types/system";
import {useI18n} from "vue-i18n" // 导入国际化插件
const treeData = ref<TreeNode[]>([]);
// 存储当前选中的节点
const selectedNode = ref<any>(null);
const treeRef = ref();
const tempEditTxt = ref('新目录');
const editStation = ref<boolean[]>([]);
const addStation = ref<boolean>(true);
const contentHeight = autoHeight(140);
const modifyDialog = ref<boolean>(false);
const del_child_label = ref<boolean>(false);
const add_child_label = ref<boolean>(true);
const modifyName = ref<string>('');
const person_data = ref<LabelUserRelation[]>([]);
const permission_value = ref<number[]>([]);

// 使用国际化功能
const {t} = useI18n();
const emit = defineEmits(['selected-node-changed']);

// 定义 trans
interface Props {
  treeName: string; // 传递的 treeName 必须是字符串
}

// 使用 defineProps 接收父组件传递的 props
const props = defineProps<Props>();

const tree_node = {
  value: 'id',
  children: 'children',
  label: 'label'
};

const addRoot = () => {
  // 查找 parent_id 为 null 的唯一根节点
  const rootNode = treeData.value.find(node => node.parent_id === null);
  if (!rootNode) return

  const rootId = rootNode.id
  tempEditTxt.value = "新知识库"
  editStation.value = editStation.value.map(() => false);
  addStation.value = false

  const newRoot: TreeNode = {
    id: Date.now(),
    label: tempEditTxt.value,
    children: [],
    parent_id: rootId,
    showButton: false,
    label_names: [],
    label_ids: [],
    tree_name: props.treeName,
    file_count: 0,
    isEditing: true,
    isNew: true
  }

  treeData.value.push(newRoot)
  treeData.value = [...treeData.value]
  newRoot.isEditing = false
  editStation.value[newRoot.id] = true
}

const append = (data: TreeNode) => {
  tempEditTxt.value = "新目录"
  editStation.value = editStation.value.map(() => false);
  addStation.value = false

  const newChild: TreeNode = {
    id: Date.now(),
    label: tempEditTxt.value,
    children: [],
    parent_id: data.id,
    showButton: false,
    label_names: [],
    label_ids: [],
    tree_name: props.treeName,
    file_count: 0,
    isEditing: true,
    isNew: true
  }

  if (!data.children) {
    data.children = []
  }

  data.children.push(newChild)
  treeData.value = [...treeData.value]
  newChild.isEditing = false
  editStation.value[newChild.id] = true
}

const remove = async (data: TreeNode, node: any) => {
  try {
    await showMessageBox(t, t("message.del-node"), "[ " + data.label + " ] " + t("message.del-node-alart"), false)
    const response = await del_tree_node(data.tree_name, data.id, false)
    if (Number(response) > 0) {
      const count = Number(response)
      showNotification("success", t("message.del-node"), "[ " + data.label + " ] " + t("message.del-node-success", {count}))
      const parent = node.parent
      const children: TreeNode[] = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      children.splice(index, 1)
      treeData.value = [...treeData.value]

    }
  } catch (error) {
    console.error("unknown error : ", error);
  }
}

const modify = async (data: TreeNode) => {
  modifyName.value = selectedNode.value.data.label
  editStation.value = editStation.value.map(() => false);
  addStation.value = false
  const response = await get_user_label(-1)

  if (response) {
    person_data.value = response
    if (data.label_ids) {
      permission_value.value = data.label_ids;
    } else {
      permission_value.value.length = 0
    }
    modifyDialog.value = true
  } else {
    console.error("unknown error")
    modifyDialog.value = false
  }

}

const save_modify_node = async () => {
  const data = selectedNode.value.data
  data.label = modifyName.value
  data.label_ids = permission_value.value
  const response = await save_tree_node(data, del_child_label.value, add_child_label.value)
  if (response != -1) {
    data.id = response
    selectedNode.value.data = data
    editStation.value[data.id] = false
    addStation.value = true
    treeData.value = await get_tree(props.treeName)
    modifyDialog.value = false
  } else {
    addStation.value = false
    modifyDialog.value = true
  }
}

const saveNode = async (data: any) => {
  if (tempEditTxt.value == '') return
  const temp_label = data.label
  data.label = tempEditTxt.value
  data.tree_name = props.treeName
  const response = await save_tree_node(data, false, false)
  if (response != -1) {
    data.id = response
    data.isNew = false
    editStation.value[data.id] = false
    addStation.value = true
  } else {
    data.label = temp_label
    editStation.value[data.id] = true
    addStation.value = false
  }
}

const closeDialog = () => {
  modifyDialog.value = false
  cancelNode(selectedNode.value.data, selectedNode.value)
}

const cancelNode = (data: any, node: any) => {
  tempEditTxt.value = data.label
  editStation.value[data.id] = false
  addStation.value = true
  if (data.isNew) {
    data.file_count = 0
    const parent = node.parent
    const children: TreeNode[] = parent.data.children || parent.data
    const index = children.findIndex((d) => d.id === data.id)
    children.splice(index, 1)
    treeData.value = [...treeData.value]
  }
}

const handleNodeDrop = async (before: any, after: any, inner: any, event: any) => {
  switch (inner) {
    case "inner":
      before.data.parent_id = after.data.id;
      break;
    case "before":
      before.data.parent_id = after.data.parent_id;
      break;
    default:
      before.data.parent_id = after.data.parent_id;
  }

  await save_tree_node(before.data, false, false)
}

// 递归函数：将所有节点的 showButton 属性设置为 false
const setShowButtonFalse = (nodes: TreeNode[]) => {
  nodes.forEach(node => {
    node.showButton = false;
    if (node.children && node.children.length > 0) {
      setShowButtonFalse(node.children);
    }
  });
};

const handleClickNode = (data: any, node: any) => {
  selectedNode.value = node;  // 将选中的节点存储
  setShowButtonFalse(treeData.value); // 将所有节点的 showButton 属性设置为 false
  data.showButton = !!data.parent_id;
  data.isEditing = true
}

const handleMouseOver = (data: any) => {
  // 仅当鼠标悬浮在选中的节点上时才显示按钮
  if (!data.parent_id) return
  const currentNode = treeRef.value ? treeRef.value.getCurrentNode() : null;
  data.showButton = currentNode && currentNode.id === data.id;
}

const refreshTree = async () => {
  treeRef.value = null
  treeData.value = await get_tree(props.treeName)
  addStation.value = true
  selectedNode.value = null
}

defineExpose({
  refreshTree
});

watch(selectedNode, (newValue) => {
  // 发出事件到父组件
  if (newValue) {
    emit('selected-node-changed', newValue);
  }
});

onMounted(async () => {
  try {
    treeData.value = await get_tree(props.treeName)
    addStation.value = true

  } catch (error) {
    console.error('Error fetching tree data:', error);
  }
});

</script>

<template>
  <el-scrollbar class="tree-content" :height="contentHeight">
    <el-tree
        ref="treeRef"
        :data="treeData"
        :props="tree_node"
        node-key="id"
        draggable
        highlight-current
        default-expanded-keys="-1"
        :expand-on-click-node="false"
        auto-expand-parent
        :icon="treeIcon"
        @node-click="handleClickNode"
        @node-drop="handleNodeDrop"
        class="tree-top"
    >
      <template #default="{ data,node }">
        <div class="top-row"
             @mouseover="handleMouseOver(data)"
             @mouseleave="data.showButton=false">
          <div v-if="!editStation[data.id]">
            <div v-if="data.file_count!=0">
              <el-badge :value="data.file_count" :max="999999" :offset="[15, 9]"
                        type="warning">
                <div style="display: flex; flex-direction: row;gap: 5px">
                  <div v-if="data.children.length === 0 && data.parent_id != -1">
                    <font-awesome-icon icon="circle" size="xs"/>
                  </div>
                  <el-tooltip
                      class="box-item"
                      :content="data.label"
                      effect="customized"
                      placement="top-start"
                      v-if="data.label.length>12"
                  >
                    <el-text v-if="data.parent_id === -1" type="primary" tag="b" truncated style="max-width: 180px">
                      {{ data.label }}
                    </el-text>
                    <el-text v-else truncated style="max-width: 180px">{{ data.label }}</el-text>
                  </el-tooltip>
                  <div v-else>
                    <el-text v-if="data.parent_id === -1" type="primary" tag="b" truncated style="max-width: 180px">
                      {{ data.label }}
                    </el-text>
                    <el-text v-else truncated style="max-width: 180px">{{ data.label }}</el-text>
                  </div>
                </div>
              </el-badge>
            </div>
            <div v-else>
              <div style="display: flex; flex-direction: row;gap: 10px">
                <div v-if="data.children.length === 0 && data.parent_id != -1">
                  <font-awesome-icon icon="circle" size="xs"/>
                </div>
                <el-tooltip
                    class="box-item"
                    :content="data.label"
                    effect="customized"
                    placement="top-start"
                    v-if="data.label.length>12"
                >
                  <el-text v-if="data.parent_id === -1" type="primary" tag="b" truncated style="max-width: 180px">
                    {{ data.label }}
                  </el-text>
                  <el-text v-else truncated style="max-width: 180px">{{ data.label }}</el-text>
                </el-tooltip>
                <div v-else>
                  <el-text v-if="data.parent_id === -1" type="primary" tag="b" truncated style="max-width: 180px">
                    {{ data.label }}
                  </el-text>
                  <el-text v-else truncated style="max-width: 180px">{{ data.label }}</el-text>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="display: flex; gap: 10px;">
            <el-input v-model="tempEditTxt"
                      resize="none" style="width: auto">
            </el-input>
            <div style="padding-top: 3px">
              <el-button v-if="data.isEditing" type="success" size="small" circle @click="saveNode(data)">
                <font-awesome-icon icon="check"/>
              </el-button>
              <el-button v-if="data.isEditing" type="danger" size="small" circle @click="cancelNode(data,node)">
                <font-awesome-icon icon="xmark"/>
              </el-button>
            </div>
          </div>
          <div v-if="data.label_names && !data.showButton" class="permission_tag">
            <el-popover placement="right" :width="300" trigger="hover" show-after=500>
              <template #reference>
                <font-awesome-icon icon="lock" v-if="data.label_names.length>0"/>
              </template>
              <div class="tags-row">
                <el-tag v-for="(item, index) in data.label_names" :key="index" type="success">
                  {{ item }}
                </el-tag>
              </div>
            </el-popover>
          </div>
          <div v-if="data.showButton && addStation">
            <el-button type="primary" size="small" circle @click="append(data)">
              <font-awesome-icon icon="plus"/>
            </el-button>
            <el-button type="primary" size="small" circle @click="modify(data)">
              <font-awesome-icon icon="pen"/>
            </el-button>
            <el-button type="danger" size="small" circle @click="remove(data,node)">
              <font-awesome-icon icon="trash-can"/>
            </el-button>
          </div>
        </div>
      </template>
    </el-tree>
    <div class="overlay-back-text">
    </div>
    <div class="overlay-text">
      <el-text size="large" tag="b" type="primary">{{ $t('knowledge.title') }}</el-text>
      <div style="align-content: center">
        <el-button type="primary" :disabled="!addStation" round @click="addRoot" size="small">{{ $t('knowledge.add-knowledge-lib') }}</el-button>
        <el-button type="primary" @click="refreshTree" size="small" circle>
          <template #default>
            <font-awesome-icon icon="arrow-rotate-right"/>
          </template>
        </el-button>
      </div>
    </div>

  </el-scrollbar>
  <el-dialog v-model="modifyDialog" width="860px"
             align-center center draggable :close-on-click-modal="false" :show-close="false">
    <template #header="">
      <div class="dialog-header">
        <span>{{ $t('message.modify-node-title') }}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
          <font-awesome-icon icon="xmark"/>
        </el-button>
      </div>
    </template>
    <el-form label-width="90px" :label-position="'right'"
    >
      <el-form-item :label="$t('message.node-name')" prop="node_name" class="item">
        <el-input v-model="modifyName"
                  :placeholder="$t('message.node-name-tip')"
                  clearable
                  show-word-limit
                  maxlength="50"/>
      </el-form-item>
      <el-form-item :label="$t('message.permission_label')" prop="permission_label" class="item">
        <el-transfer
            v-model="permission_value"
            :data="person_data"
            :props="{key: 'label_id', label: 'label_name'}"
            target-order="unshift"
            filterable
            :titles="[$t('user.user-label'), $t('message.node-permission')]"
            :format="{noChecked: '${total}', hasChecked: '${checked}/${total}'}"
        />
        <span class="notice-txt">{{ $t("message.modify-node-tips") }}</span>
        <el-row :gutter="20" style="height:auto;margin-top: 0">
          <el-col :span="12" class="right-txt">
            <el-switch
                v-model="add_child_label"
                inline-prompt
                :active-text="$t('message.yes')"
                :inactive-text="$t('message.no')"
            />
            <span> {{ $t('message.modify-add-child') }} </span>
          </el-col>
          <el-col :span="12" class="right-txt">
            <el-switch
                v-model="del_child_label"
                inline-prompt
                :active-text="$t('message.yes')"
                :inactive-text="$t('message.no')"
            />
            <span> {{ $t('message.modify-del-child') }} </span>
          </el-col>
        </el-row>
      </el-form-item>
    </el-form>

    <template #footer>
      <div>
        <el-button type="primary" @click="save_modify_node">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.save') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.right-txt {
  column-gap: 10px;
  display: flex;
  align-content: center;
  justify-content: start;
}

.el-transfer {
  --el-transfer-panel-width: 259px;
  --el-transfer-border-radius: 20px;
  --el-transfer-border-color: #{$table-header-bg-color};
  --el-transfer-panel-header-bg-color: #{$table-header-bg-color};
  margin-top: 10px;

  html.dark & {
    --el-transfer-border-color: #{$menu-sub-dark-bg};
    --el-transfer-panel-header-bg-color: #{$menu-sub-dark-bg};
  }
}

.el-transfer :deep(.el-transfer-panel) {
  border-radius: 20px !important;
}

.item {
  padding-right: 50px;
  margin: 5px 0 5px 0;
}

.notice-txt {
  display: flex;
  width: 100%;
  justify-content: left;
  align-content: center;
  margin-bottom: 10px;
  font-style: italic;
  color: $chat-pop-dark;
}

.permission_tag {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px;
}

.el-badge {
  border: 0;
}

.top-row {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: auto;
  width: 100%;
  padding-right: 10px;
  padding-left: 5px;
}

.el-tree :deep(.el-tree-node__content) {
  height: 38px;
  border-radius: 20px;
}

.el-tree :deep(.el-tree-node.is-current > .el-tree-node__content) {
  background-color: $table-header-bg-color;

  html.dark & {
    background-color: $menu-hover-dark-bg;
  }
}

.tree-content {
  padding-right: 15px;
  padding-left: 15px;


  html.dark & {
    background-color: $main-background-dark; /* 深色模式背景色 */
  }
}

.tree-top {
  margin-top: 3px;
  min-width: 300px;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 确保宽度和 el-tree 一样 */
  height: 40px;
  background-color: $table-header-bg-color;
  z-index: 10; /* 确保覆盖在树的上方 */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid $table-header-text-color;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  html.dark & {
    background-color: $menu-sub-dark-bg;
  }
}

.overlay-back-text{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 确保宽度和 el-tree 一样 */
  height: 41px;
  background-color: white;
  z-index: 10; /* 确保覆盖在树的上方 */
  box-sizing: border-box;
  display: flex;
  html.dark & {
    background-color: $main-background-dark;
  }
}

.content_title {
  font-size: 16px;
}

.tags-row {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap; /* 允许换行，防止内容超出容器 */
  gap: 5px; /* 设置子元素间的间距（替代 margin-right） */
}

.el-tree-node__content {
  white-space: nowrap; /* 禁止内容换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}

</style>