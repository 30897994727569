// src/services/robotService.ts

import axios from '@/setups/axios-setup'
import {Robot} from '@/types/Robot'
import config from '@/services/config'


export const createOrUpdateRobot = async (robot: Robot) => {
    try {
        // 使用FormData构建请求数据
        const formData = new FormData();
        formData.append('robot_data', JSON.stringify(robot)); // 添加用户数据

        const response = await axios.post(`${config.apiRobotUrl}/robot`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const deleteRobots = async (ids: number[]) => {
    try {
        const response = await axios.delete(`${config.apiRobotUrl}/robot`, {data: ids});
        return response.data.success;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const getRobots = async (keyword: string | null, publish: number, page: number, pagesize: number) => {

    // 构建请求数据对象
    const data = {
        search_keyword: keyword, // 搜索关键字
        page: page,                // 当前页码
        page_size: pagesize,       // 每页项目数量
        status: publish            // 发布状态
    };

    const response = await axios.post(`${config.apiRobotUrl}/robots`, data);
    return response.data;

};

export const getRobotById = async (rb_Id: number) => {
    try {
        const response = await axios.get(`${config.apiRobotUrl}/robot/${rb_Id}`);
        return response.data.message;
    } catch (error) {
        console.log(error)
        throw error
    }
};

export const getRobotsSummary = async () => {
    try {
        const response = await axios.get(`${config.apiRobotUrl}/robots/summary`);
        return JSON.parse(response.data);
    } catch (error) {
        console.log(error)
        throw error
    }
};
