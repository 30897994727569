<script setup lang="ts">
import {computed, ref, watch} from "vue" // 导入 Vue 的核心功能
import {useI18n} from "vue-i18n" // 导入国际化插件
import {FormInstance, genFileId, UploadFile, UploadInstance, UploadProps, UploadRawFile} from 'element-plus' // 导入 Element Plus 的表单和上传组件
import {User} from "@/types/User" // 导入 User 类型定义
import {useUserStore} from '@/stores/userStore' // 导入用户存储
import {showNotification} from "@/services/tools" // 导入工具函数
import {validateConfirmPassword, validateMobile, validatePassword} from "@/services/validators"
// 使用用户存储和国际化
const userStore = useUserStore() // 使用用户存储
const {t} = useI18n() // 使用国际化

// 响应式状态变量
const newUserMessage = ref<string>('') // 新用户信息提示
const userForm = ref<FormInstance>() // 用户表单实例
const createUserDialog = ref(false) // 创建用户对话框显示状态
const avatarPic = ref<File | null>(null) // 头像文件
const avatar_upload = ref<UploadInstance>() // 上传实例
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const user = ref<User>({} as User) // 创建用户对象
const UserData = ref({ // 用户数据对象
  avatar_url: '',
  nickName: '',
  loginName: '',
  password: '',
  confirmPassword: '',
  mobile: '',
  email: '',
  membership_end_date: null as Date | null,
  native_language: '',
  fee_times: 0,
  role: false
})
// 初始化用户数据的默认值
const defaultUserData = {
  avatar_url: '',
  nickName: '',
  loginName: '',
  password: '',
  confirmPassword: '',
  mobile: '',
  email: '',
  membership_end_date: null as Date | null,
  native_language: '',
  fee_times: 0,
  role: false
};

const disabledDate = (time: Date) => {
  return time.getTime() < new Date().setHours(0, 0, 0, 0)
}

// 定义组件的 props 和 emits
const props = defineProps({ // 定义 props
  isShow: {
    type: Boolean,
    default: false
  },
  userinfo: {
    type: Object as () => User,
    default: null
  }
})
const emit = defineEmits(["refreshList", "update:isShow"]) // 定义 emits

// 监听 props.isShow 的变化并初始化相关状态
watch(() => props.isShow, (val) => {
  createUserDialog.value = val // 设置对话框显示状态
  newUserMessage.value = "" // 清空提示信息
}, {immediate: true}) // 立即执行

// 监听 props.userinfo 的变化并初始化相关状态
watch(() => props.userinfo, (newVal) => {
  if (newVal) {
    user.value = newVal;
    ini_form(user.value)
  } else {
    user.value = {} as User;
  }
}, {immediate: true}) // 立即执行

// 表单验证规则
const userRules = computed(() => ({
  nickName: [{required: false, max: 30, message: t('user.nickname-check'), trigger: 'blur'}], // 昵称验证规则
  loginName: [{required: true, max: 30, message: t('user.reg-login-name-check'), trigger: 'blur'}], // 登录名验证规则
  password: [
    {
      required: !user.value.user_id,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validatePassword(t, rule, value, callback, !user.value.user_id) // 密码验证规则
    }
  ],
  confirmPassword: [
    {
      required: !user.value.user_id,
      trigger: 'blur',
      validator: (rule: any, value: any, callback: any) => validateConfirmPassword(t, rule, value, callback, UserData.value.password) // 确认密码验证规则
    }
  ],
  mobile: [{
    required: false,
    validator: (rule: any, value: any, callback: any) => validateMobile(t, rule, value, callback), // 手机号码验证规则
    trigger: 'blur'
  }],
  email: [{type: 'email', message: t('user.email-invalid'), trigger: ['blur', 'change']}], // 格式验证
}))

// 实用函数：刷新父列表
const refreshFatherList = () => {
  try {
    emit("refreshList") // 触发 refreshList 事件
  } catch (error) {
    console.error('Error refreshing father list:', error) // 打印错误信息
  }
}

// 关闭对话框并重置表单
const closeDialog = async () => {
  try {
    createUserDialog.value = false // 隐藏对话框

    newUserMessage.value = "" // 清空提示信息
    avatar_upload.value!.clearFiles() //清空文件列表
    avatarPic.value = null //清空文件
    refreshFatherList()
    emit('update:isShow', false) // 触发 update:isShow 事件
  } catch (error) {
    console.error('Error closing dialog:', error) // 打印错误信息
  }
}

const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url!
  dialogVisible.value = true
}

// 上传文件超出限制时的处理
const handleExceed: UploadProps['onExceed'] = (files) => {
  try {
    avatar_upload.value!.clearFiles() // 清空文件列表
    const file = files[0] as UploadRawFile
    file.uid = genFileId() // 生成文件 ID
    avatar_upload.value!.handleStart(file) // 开始上传文件
    avatarPic.value = file // 更新头像文件
  } catch (error) {
    console.error('Error handling file exceed:', error) // 打印错误信息
  }
}

// 上传文件状态改变时的处理
const handleChange: UploadProps['onChange'] = (uploadFile: UploadFile) => {
  try {
    UserData.value.avatar_url = ""
    if (uploadFile.status === 'ready' && uploadFile.raw) {
      avatarPic.value = uploadFile.raw as File // 更新头像文件
    }
  } catch (error) {
    console.error('Error handling file change:', error) // 打印错误信息
  }
}

// 上传头像之前的检查
const beforeAvatarUpload = (rawFile: File | null) => {
  try {
    if (!rawFile) return true
    const isAllowedType = ['image/jpeg', 'image/png', 'image/gif'].includes(rawFile.type) // 检查文件类型
    if (!isAllowedType) {
      newUserMessage.value = t('user.avatar_format')
      return false
    }
    if (rawFile.size / 1024 / 1024 > 2) {
      newUserMessage.value = t('user.avatar_size')
      return false
    }

    newUserMessage.value = ""
    avatarPic.value = rawFile
    return true
  } catch (error) {
    console.error('Error before uploading avatar:', error) // 打印错误信息
    return false
  }
}

// 上传用户信息
const save_user = async (formEl: FormInstance | undefined) => {
  if (!formEl) return


  try {
    user.value.avatar_url = UserData.value.avatar_url // 设置用户头像 URL
    user.value.name = UserData.value.nickName // 设置用户昵称
    user.value.login_name = UserData.value.loginName // 设置用户登录名
    user.value.password = UserData.value.password // 设置用户密码
    user.value.mobile = UserData.value.mobile // 设置用户手机
    user.value.email = UserData.value.email // 设置用户邮箱
    user.value.native_language = UserData.value.native_language // 设置用户母语
    user.value.role = UserData.value.role ? 1 : 0 // 设置管理员角色

    if (UserData.value.membership_end_date) {
      //会员结束日设置为选择日期的23:59:59
      const temp_date = new Date(UserData.value.membership_end_date)
      // 设置小时为当天的 23:59:59
      temp_date.setHours(23, 59, 59, 0)
      // 使用 moment 格式化为本地时间字符串（YYYY-MM-DD HH:MM:SS）
      // 保存修改后的日期字符串到 user.value.membership_end_date
      user.value.membership_end_date = temp_date;

    } else {
      user.value.membership_end_date = null
    }

    if (UserData.value.fee_times === 0) {
      user.value.fee_times = 1
    } else {
      user.value.fee_times = UserData.value.fee_times
    }


    newUserMessage.value = "" // 清空提示信息
    try {
      const valid = await formEl.validate() // 验证表单

      if (valid) {
        if (!beforeAvatarUpload(avatarPic.value)) return
        const response = await userStore.register(user.value, avatarPic.value) // 注册用户，允许 avatarPic 为 null
        if (response === '0') {
          UserData.value = {...defaultUserData} //清空userData数据
          refreshFatherList() // 刷新父组件列表
          await closeDialog() // 关闭对话框
          showNotification('success', t('message.success'), t('user.newuser-success-info')) // 显示成功通知
        }
      }
    } catch (error: any) {
      let detail = "null"
      newUserMessage.value = "" // 显示验证错误提示
      if (error.response) {
        detail = error.response.data.detail;
      }
      switch (detail) {
        case 'LoginNameExists':
          newUserMessage.value = t('user.newuser-name-exist'); // 注册名已存在
          break;
        case 'UserNotFound':
          newUserMessage.value = t('user.newuser-name-exist'); // 注册名已存在
          break;
        case 'CreateSchemaFailed':
          newUserMessage.value = t('user.create-schema-error'); // 未知错误
          break;
        case 'null':
          break;
        default:
          showNotification('error', '未知状态', error.response.data.detail);
          break;
      }
    }

  } catch (error) {
    console.error('Failed to validate form:', error) // 打印错误信息
    newUserMessage.value = t('user.unknown-error') // 显示未知错误提示
  }
}

const ini_form = async (user: User) => {
  if (user) {
    UserData.value.avatar_url = user.avatar_url || '' // 设置用户头像 URL
    UserData.value.nickName = user.name || ''// 设置用户昵称
    UserData.value.loginName = user.login_name || '' // 设置用户登录名
    UserData.value.password = '' // 设置用户密码
    UserData.value.mobile = user.mobile || ''// 设置用户手机
    UserData.value.email = user.email || ''// 设置用户邮箱
    UserData.value.membership_end_date = user.membership_end_date || null; //设置会员到期日
    UserData.value.fee_times = user.fee_times || 0
    UserData.value.native_language = user.native_language || ''// 设置用户母语
    UserData.value.role = user.role == 1 //设置管理员角色
  }
}

</script>

<template>
  <el-dialog :visible="createUserDialog"  width="900px"
             align-center center draggable :close-on-click-modal="false" :show-close="false">
    <template #header="">
      <div class="dialog-header">
        <span>{{$t('user.register-title')}}</span>
        <el-button type="danger" @click="closeDialog" circle size="small">
           <font-awesome-icon icon="xmark" />
        </el-button>
      </div>
    </template>
    <el-form ref="userForm" :model="UserData" :rules="userRules" label-width="100px" :label-position="'right'">

      <el-row>
        <el-alert v-if="newUserMessage" type="warning" :description="newUserMessage" :closable="false" show-icon
                  :effect="'light'" class="reg-alert">
        </el-alert>

        <el-col :span="12">
          <div class="col-left">
            <el-form-item :label="$t('user.nickname')" prop="nickName">
              <el-input v-model="UserData.nickName"
                        :placeholder="$t('user.nickname-tip')"
                        clearable
                        show-word-limit
                        maxlength="30">
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('user.login-name')" prop="loginName">
              <el-input v-model="UserData.loginName"
                        :placeholder="$t('user.login-name-tip')"
                        clearable
                        show-word-limit
                        maxlength="20"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.password')" prop="password">
              <el-input type="password"
                        v-model="UserData.password"
                        :placeholder="$t('user.input-password-tip')"
                        show-password
                        clearable
                        show-word-limit
                        maxlength="50"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.confirm-password')" prop="confirmPassword">
              <el-input type="password" v-model="UserData.confirmPassword"
                        :placeholder="$t('user.confirm-password-tip')" autocomplete="off" show-password
                        clearable></el-input>
            </el-form-item>
            <el-form-item class="avatar-div" :label="$t('user.avatar')">
              <div v-if="UserData.avatar_url" class="div-row">
                <el-image
                    :src="UserData.avatar_url"
                    alt="Preview Image"
                    fit="cover"
                    class="avatar-preview"
                />
              </div>
              <el-upload list-type="picture-card"
                         :on-exceed="handleExceed"
                         :on-change="handleChange"
                         :on-preview="handlePictureCardPreview"
                         :limit="1"
                         :auto-upload="false"
                         ref="avatar_upload">
                <font-awesome-icon icon="circle-plus" class="avatar-uploader-icon"/>
              </el-upload>
              <el-dialog v-model="dialogVisible" width="400px">
                <el-image
                    :src="dialogImageUrl"
                    alt="Preview Image"
                    fit="cover"
                    class="preview_pic"
                />
              </el-dialog>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="col-right">

            <el-form-item :label="$t('user.mobile')" prop="mobile">
              <el-input v-model="UserData.mobile"
                        :placeholder="$t('user.mobile-tip')"
                        clearable
                        show-word-limit
                        maxlength="15"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.email')" prop="email">
              <el-input v-model="UserData.email"
                        :placeholder="$t('user.email-tip')"
                        clearable
                        show-word-limit
                        maxlength="40"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.native_language')" prop="native_language">
              <el-input v-model="UserData.native_language"
                        :placeholder="$t('user.native_language-tip')"
                        clearable
                        show-word-limit
                        maxlength="20"></el-input>
            </el-form-item>
            <el-form-item :label="$t('user.membership_end_date')" prop="membership_end_date">
              <el-date-picker
                  v-model="UserData.membership_end_date"
                  type="date"
                  :placeholder="$t('user.membership_end_date_tip')"
                  :disabled-date="disabledDate"
                  :size="'default'"
                  style="width: 100%;"
              />
            </el-form-item>
            <el-form-item :label="$t('user.manager-role')" prop="role">
              <el-switch
                  v-model="UserData.role"
                  inline-prompt
                  :active-text="$t('message.yes')"
                  :inactive-text="$t('message.no')"
              />
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>

    <template #footer>
      <div>
        <el-button type="primary" @click="save_user(userForm)">
          <font-awesome-icon icon="check" class="icon-text-button"/>
          {{ $t('message.save') }}
        </el-button>
        <el-button type="danger" @click="closeDialog" plain>
          <font-awesome-icon icon="xmark" class="icon-text-button"/>
          {{ $t('message.cancel') }}
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/colors.scss';


.avatar-uploader-icon {
  color: $header-font-color-light;
  width: 50px;
  height: 50px;
  text-align: center;

  html.dark & {
    color: $menu-hover-dark-bg;

  }
}

.avatar-uploader-icon:hover {
  color: $menu-hover-bg;

  html.dark:hover & {
    color: $table-header-text-color;

  }
}


.avatar-div {
  width: 100%;
  text-align: left;

}

/* 注册专用警告框样式 */
.reg-alert {
  min-height: 50px; /* 最小高度适用于较少内容 */
  margin: 5px 0 10px 0;
  padding: 0 30px 0 30px; /* 无顶部外边距 */
  width: 97%;
}

.el-row {
  display: flex;
  align-items: flex-start;
  padding: 0;
}

.el-col {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 5px;
}

.el-input {
  width: 320px;
}

.preview_pic {
  width: 360px; /* 限制图片最大宽度为100% */
  height: auto; /* 高度自适应 */
  display: block;
  margin: 10px auto; /* 图片居中 */
  border-radius: 20px;
}


.avatar-preview {
  height: 148px;
  width: 148px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
}

.div-row {
  height: 148px;
  width: 148px;
  display: flex; /* 使用 flex 布局以居中图片 */
  justify-content: center;
  align-items: center;
  overflow: hidden; /* 隐藏超出容器的部分 */
  border: 0;
  margin-right: 5px;

}

</style>
