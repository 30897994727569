import {Prompt} from "@/types/Prompt";

export interface Model {
    model_id?: number;
    supplier: string;
    name: string;
    model: string;
    size: string;
    desc: string;
    free: boolean;
}

export interface ChatMessage {
    role: 'system' | 'user' | 'ai';
    session_id:string;
    session_name:string;
    content: string;
    sys_content: string;
    createdAt: number;
    topic: string;
    robot_content: string;
    relevance: boolean;
    no_prompt: number;
    lang:string;
    source:string;
}

export interface ChatSession{
    session_id:string;
    session_name:string;
}

export interface StreamResponse {
    content: string;
    topic: string;
    sys_content: string;
    robot_content: string;
    relevance:boolean;
    source:string;
}

export enum ChatErrorCode {
    SUCCESS = "0",
    INVALID_INPUT = "1",
    DATABASE_ERROR = "2",
    UNKNOWN_ERROR = "3",
    NON_COMPLIANCE = "4",
    COMPLIANCE = "5"
}

