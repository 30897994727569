import {defineStore} from 'pinia';
import {ChatErrorCode, ChatMessage, ChatSession, Model, StreamResponse} from "@/types/LLM";
import {
    chat_stream,
    getAnswer,
    getChatHistory,
    getChatSessionHistory,
    getModels,
    save_chat
} from "@/services/llmService"
import {Prompt} from '@/types/Prompt';


export const useLLMStore = defineStore('llmStore', {
    state: () => ({
        chat_msg: {} as ChatMessage,
        chat_history: [] as ChatMessage[],
        chat_session: [] as ChatSession[],
        streamAnswer: [] as string[],
        streaming: false,
        prompt: {} as Prompt,
        modelsList: [] as Model[],
        modelsTotal: 0,

    }),
    actions: {
        async get_Models(keyword: string | null, page: number, pageSize: number): Promise<number> {
            try {
                const response = await getModels(keyword, page, pageSize);
                if (response != null && 'models' in response) {
                    this.modelsList = response.models
                    this.modelsTotal = response.total;
                    localStorage.setItem("model_page_size", pageSize.toString());
                    return response.total;
                }
                return 0
            } catch (error) {
                console.error('unknown error: ', error);
                return 0
            }
        },

        async sendToLLM(message: ChatMessage[]) {
            this.streaming = false;
            return await getAnswer(message)
        },

        async sendToLLMStreaming(msgs: ChatMessage[]) {
            this.streaming = true;
            let first = true;
            for (let i = 0; i <= 1; i++) {
                this.streamAnswer.push("");
            }

            // 初始化Chat_msg
            this.chat_msg.sys_content = msgs[msgs.length - 1].sys_content
            this.chat_msg.robot_content = msgs[msgs.length - 1].robot_content;
            this.chat_msg.topic = msgs[msgs.length - 1].topic;
            this.chat_msg.relevance = true;

            try {
                await chat_stream(msgs, (message: StreamResponse | Prompt | string) => {
                    if (typeof message != 'string') {
                        if (first && 'topic' in message) {
                            first = false
                            this.chat_msg.sys_content = message.sys_content;
                            this.chat_msg.robot_content = message.robot_content;
                            this.chat_msg.topic = message.topic;
                            this.chat_msg.relevance = message.relevance;
                            this.chat_msg.source = message.source;
                        } else if (typeof message == 'object') {
                            return message  //弹出任务框，之后优化
                        }
                    } else {
                        this.streamAnswer[msgs.length] += message
                    }
                });

                return null;

            } finally {
                this.streaming = false;
            }
        },

        async saveNowChat(msgs: ChatMessage[]) {
            try {
                return await save_chat(msgs)
            } finally {
                this.streaming = false;
            }
        },

        async loadChatList(fromDate: Date, toDate: Date) {
            try {
                const response = await getChatHistory(fromDate, toDate)

                this.chat_history = response.history
                this.chat_session = response.session
                this.streamAnswer.length = 0
                if (response.history) {
                    for (const item of this.chat_history) {
                        this.streamAnswer.push(item.content)
                    }
                }

            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.streaming = false;
            }
        },

        async loadChaSessionList(sessionId: string, fromDate: Date | null, toDate: Date | null) {
            try {
                if (!sessionId) return {"error": ChatErrorCode.INVALID_INPUT, "message": "sessionId不能为空"}
                const response = await getChatSessionHistory(sessionId, fromDate, toDate)

                if ('history' in response) {
                    this.chat_history = response.history
                    this.streamAnswer.length = 0
                    if (response.history) {
                        for (const item of this.chat_history) {
                            this.streamAnswer.push(item.content)
                        }
                    }
                }
            } catch (error) {
                console.error(error);
                throw error
            } finally {
                this.streaming = false;
            }
        }
    }
});